import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import swapLogo from "./assets/images/swap.png";
import { ethers, isAddress } from "ethers";
import { getFetcher, postFetcher } from "./helpers";
import { TOKEN_KEY } from "./constants";
import useSWR from "swr"; // 导入SWR
import { toast } from "sonner";

const CryptoTransfer = () => {
  const [activationCodes, setActivationCodes] = useState(0);
  const [showActivationCodes, setShowActivationCodes] = useState(false);
  const [targetAddress, setTargetAddress] = useState("");
  const [amount, setAmount] = useState(1);
  const [transferRecords, setTransferRecords] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  useEffect(() => {
    checkWalletConnection();
  }, []);

  const { data: balanceData } = useSWR(
    isConnected ? "/zone/user/getbalance" : null,
    getFetcher
  );

  useEffect(() => {
    if (balanceData && balanceData.code === 200) {
      setActivationCodes(balanceData.data.balance);
    }
  }, [balanceData]);

  const { data: tradingHistoryData } = useSWR(
    isConnected ? "/zone/user/gettradinghistory" : null,
    getFetcher,
    { refreshInterval: 3000 }
  );

  useEffect(() => {
    if (tradingHistoryData && tradingHistoryData.code === 200) {
      setTransferRecords(tradingHistoryData.data.trading_history);
    }
  }, [tradingHistoryData]);

  const checkWalletConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await provider.listAccounts();
        if (accounts.length > 0) {
          setWalletAddress(accounts[0].address); // 直接使用账户地址
          setIsConnected(true);
        }
      } catch (error) {
        console.error("Failed to check wallet connection:", error);
      }
    }
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
        setIsConnected(true);

        const message = `Welcome to the Trading System!\nClick "Sign" to sign in. No password needed!\nADDRESS ${address}`;
        const signature = await signer.signMessage(message);

        const response = await postFetcher("/index/login", {
          address: address,
          sign: signature,
        });
        setActivationCodes(response.data.balance);

        localStorage.setItem(TOKEN_KEY, response.data.login_token);
      } catch (error) {
        disconnectWallet();
        console.error("Failed to connect to wallet:", error);
      }
    } else {
      alert("请安装 MetaMask!");
    }
  };

  const disconnectWallet = () => {
    setWalletAddress("");
    setIsConnected(false);
    setShowLogout(false);
  };

  const formatAddress = (address) => {
    if (typeof address === "string" && address.length >= 10) {
      return `${address.slice(0, 6)}......${address.slice(-4)}`;
    }
    return String(address); // 确保返回字符串
  };

  const AddressDisplay = ({ address }) => {
    return <span className="text-white">{formatAddress(address)}</span>;
  };

  const AddressLink = ({ address }) => (
    <a
      href={`https://etherscan.io/address/${address}`}
      target="_blank"
      rel="noopener noreferrer"
      className="text-green-500 hover:underline"
    >
      {formatAddress(address)}
    </a>
  );

  const handleTransfer = async () => {
    if (!isConnected) {
      toast.error("请先连接钱包");
      return;
    }
    if (!isAddress(targetAddress)) {
      toast.error("无效的地址");
      return;
    }

    const amountInt = Number(amount);
    if (isNaN(amountInt) || amountInt <= 0) {
      toast.error("无效的数量");
      return;
    }

    if (amountInt > activationCodes) {
      toast.error("激活码数量不足");
      return;
    }

    try {
      const response = await postFetcher("/zone/user/transfer", {
        to: targetAddress,
        amount: amountInt,
      });

      if (response.code === 200) {
        setActivationCodes(response.data.balance);
        toast.success("转移成功");
      } else {
        toast.error("转移失败");
      }
      setTargetAddress("");
      setAmount(1);
    } catch (error) {
      toast.error("转移失败");
      console.error("Transfer failed:", error);
    }
  };

  const toggleActivationCodes = () => {
    setShowActivationCodes(!showActivationCodes);
  };

  const validateEVMAddress = (address) => {
    const evmAddressRegex = /^0x[a-fA-F0-9]{40}$/;
    return evmAddressRegex.test(address);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-7xl mx-auto">
        <header className="flex justify-between items-center mb-8">
          <div className="flex-1">
            <img src={swapLogo} alt="Logo" className="h-8" />
          </div>
          <div className="flex-1 flex justify-end">
            {isConnected ? (
              <div className="relative">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded"
                  onClick={() => setShowLogout(!showLogout)}
                >
                  <AddressDisplay address={walletAddress} />
                </button>
                {showLogout && (
                  <button
                    className="absolute top-full right-0 mt-2 bg-red-500 text-white px-4 py-2 rounded"
                    onClick={disconnectWallet}
                  >
                    退出
                  </button>
                )}
              </div>
            ) : (
              <button
                onClick={connectWallet}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                链接钱包
              </button>
            )}
          </div>
        </header>

        <main className="max-w-md mx-auto">
          <div className="bg-gray-800 rounded-lg p-4 mb-4">
            <h2 className="text-lg mb-2">我的激活码数量</h2>
            <div className="flex justify-between items-center">
              <span className="text-4xl font-bold">{activationCodes}</span>
              <button
                onClick={toggleActivationCodes}
                className="text-green-500 border border-green-500 px-4 py-1 rounded"
              >
                查看激活码
              </button>
            </div>
          </div>

          <div className="bg-gray-800 rounded-lg p-4 mb-4">
            <div className="mb-4">
              <label className="block mb-1">目标地址</label>
              <input
                type="text"
                value={targetAddress}
                onChange={(e) => {
                  const address = e.target.value;
                  if (validateEVMAddress(address) || address === "") {
                    setTargetAddress(address);
                  }
                }}
                className="w-full bg-gray-700 rounded px-3 py-2"
                placeholder="0x..."
              />
            </div>
            <div className="mb-4">
              <label className="block mb-1">数量</label>
              <input
                type="number"
                value={amount}
                onChange={(e) =>
                  setAmount(Math.max(1, parseInt(e.target.value) || 1))
                }
                className="w-full bg-gray-700 rounded px-3 py-2"
                min="1"
              />
            </div>
            <button
              onClick={handleTransfer}
              className="w-full bg-green-500 text-white py-2 rounded"
            >
              转移
            </button>
          </div>

          <div className="bg-gray-800 rounded-lg p-4">
            <h3 className="text-lg mb-2">转移记录</h3>
            <ul>
              {transferRecords.map((record, index) => (
                <li key={index} className="text-sm mb-1">
                  从 <AddressLink address={`0x${record.from}`} /> 转移
                  <span className="text-green-500"> {record.amount} </span>
                  个激活码到
                  <AddressLink address={`0x${record.to}`} />
                </li>
              ))}
            </ul>
          </div>
        </main>
      </div>

      {showActivationCodes && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg w-80 overflow-hidden mt-[-20vh]">
            <div className="flex justify-between items-center p-4">
              <h3 className="text-lg font-semibold text-gray-300">
                我的激活码
              </h3>
              <button
                onClick={toggleActivationCodes}
                className="text-gray-400 hover:text-white"
              >
                <X size={20} />
              </button>
            </div>
            <div className="bg-gray-900 p-4">
              {/* <ul className="space-y-2">
                {["SJ9AK0", "SJ9AK0", "SJ9AK0", "SJ9AK0", "SJ9AK0"].map(
                  (code, index) => (
                    <li key={index} className="text-lg font-mono text-white">
                      {code}
                    </li>
                  )
                )}
              </ul> */}
              <div className="text-center text-gray-400">游戏上线即可查看</div>
            </div>
          </div>
        </div>
      )}
      <footer className="text-center p-4 absolute inset-x-0 bottom-0">
        <p className="mb-1">
          Powered by{" "}
          <a
            href="https://thlm.com/"
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            THLM
          </a>{" "}
          &{" "}
          <a
            href="https://nanon.network/"
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nanon Network
          </a>
        </p>
      </footer>
    </div>
  );
};

export default CryptoTransfer;
